* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Lato", sans-serif;
	line-height: 1.5;
	font-size: 17px;
}

body {
	font-size: 1rem;
	color: var(--font-color);
}

a {
	color: var(--title-color);
}

:root {
	--title-color: #023b92;
	--background-color: #f3f8ff;
	--font-color: #414042;
}

h4 {
	color: var(--title-color);
}

.para-large {
	font-size: 1.75rem;
	font-weight: 200;
	margin-bottom: 0.75rem;
	line-height: 1.2;
}

.section {
	margin-top: 6rem;
}

.service-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 0;
	border-bottom: 1px solid grey;
	transition: all;
}

.service-title h3:hover {
	color: var(--title-color);
	/* margin-left: 2rem; */
	transition: 0.15s ease-out;
}

.service-list {
	margin-top: 1rem;
	margin-left: 1rem;
	margin-bottom: 2rem;
	color: var(--font-color);
	transition: all ease-in;
}

.tel {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}

.email {
	color: var(--title-color);
	cursor: pointer;
	margin-bottom: 2rem;
}

.layout {
	display: grid;
	margin: 0 auto;
	max-width: 2400px;
	grid-gap: 1rem;
	grid-template-rows: auto;
	grid-template-columns: repeat(5, 1fr);
	position: relative;
	width: 100%;
	height: 100vh;
}

.inner-layout {
	padding: 0 1.5rem;
	max-width: 1300px;
	grid-column: 2/6;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: auto;
	grid-gap: 1rem;
	height: 100vh;
}

.logo-container img {
	grid-column: 1/2;
	margin-top: 5rem;
	position: absolute;
	left: 5rem;
	padding-right: 1rem;
	width: 240px;
	height: auto;
}

.logo-container {
	grid-column: 1/3;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("./img/unsplash_boardroom_1.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
}

.logo-container:before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.45);
}

.content {
	/* z-index: 100; */
	grid-column: 3/6;
	overflow-y: scroll;
	/* hide scrollbar but allow scrolling */
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll;
}

.content::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

@media screen and (max-width: 800px) {
	.para-large {
		font-size: 1.55rem;
	}
	p,
	li {
		font-size: 0.95rem;
	}
	.logo-container {
		grid-column: 1/6;
		grid-row: 1/2;
		height: 40vh;
	}

	.logo-container img {
		background-position-y: -10rem;
	}

	.inner-layout {
		grid-column: 1/6;
		grid-template-rows: repeat(2, 1fr);
	}

	.section:nth-child(1) {
		margin-top: 0;
	}

	.content {
		grid-column: 1/6;
		grid-row: 2/3;
		margin-top: -3rem;
	}

	.inner-layout img {
		grid-column: 1/3;
		left: 1.5rem;
	}
}
